.container {
  padding-left: 900px;
}

.siderbar {
  background-color: #000;
  width: 900px;
  position: fixed;
  inset: 0;
}

.siderbar .getappbanner {
  text-align: center;
  width: 900px;
  position: absolute;
  bottom: 100px;
  left: 0;
  right: 0;
}

.navbar {
  z-index: 9999;
  background-color: #fff;
  margin-left: 900px;
  padding: 20px 50px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

@keyframes comeIn {
  0% {
    transform: scale(0);
  }

  75% {
    transform: scale(1.03);
  }

  100% {
    transform: scale(1);
  }
}

.xmasonry .xblock {
  transition: left .3s, top .3s;
  animation: .5s comeIn;
}

.xblock .cardblock {
  padding: 10px;
}
/*# sourceMappingURL=index.f15f3be8.css.map */
