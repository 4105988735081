.container {
  padding-left: 900px;
}

.siderbar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000000;
  width: 900px;
}

.siderbar .getappbanner {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100px;
  text-align: center;
  width: 900px;
}

.navbar {
  background-color: #ffffff;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px 50px;
  margin-left: 900px;
}

@keyframes comeIn {
    0% { transform: scale(0) }
    75% { transform: scale(1.03) }
    100% { transform: scale(1) }
}

.xmasonry .xblock {
    animation: comeIn ease 0.5s;
    animation-iteration-count: 1;
    transition: left .3s ease, top .3s ease;
}

.xblock .cardblock {
    padding: 10px;
}
